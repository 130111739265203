import { isNumber, isObject } from "lodash";
import { AxiosError } from "axios";

export const handleCartContent = (pulse: boolean = true) => {
    const cartElement = <HTMLElement>document.querySelector("#Cart");
    if (cartElement) {
        try {
            const cartStorage = window.localStorage.getItem("at.reg.cart");
            const cart = JSON.parse(cartStorage);
            cartElement.classList.toggle("empty", cart.state.domains.length === 0);
            cartElement.dataset.items = cart.state.domains.length;
            if (pulse) {
                cartElement.classList.add("pulse");
                setTimeout(() => {
                    cartElement.classList.remove("pulse");
                }, 250);
            }
        } catch {}
    }
};

export const parseLocaleNumber = (stringNumber: string, locale: string = "de") => {
    const thousandSeparator = Intl.NumberFormat(locale)
        .format(11111)
        .replace(/\p{Number}/gu, "");
    const decimalSeparator = Intl.NumberFormat(locale)
        .format(1.1)
        .replace(/\p{Number}/gu, "");

    return parseFloat(
        stringNumber
            .replace(new RegExp("\\" + thousandSeparator, "g"), "")
            .replace(new RegExp("\\" + decimalSeparator), "."),
    );
};

export const makeTwoWayObject = (original: object) => {
    const objEntries = Object.entries(original);
    return Object.fromEntries([...objEntries, ...objEntries.map((e) => [...e].reverse())]);
};

export const objectsEqual = (obj1: Object, obj2: Object) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    // a forEach loop is not disrupted by a return
    for (const key of keys1) {
        // @ts-ignore obj1 has key
        const val1 = obj1[key];
        // @ts-ignore obj1 has key
        const val2 = obj2[key];

        const areObjects = isObject(val1) && isObject(val2);
        if ((areObjects && !objectsEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
            return false;
        }
    }

    return true;
};

export const getResponseError = (axiosError: AxiosError): Array<{ key?: string; error: string }> => {
    const data = axiosError.response.data;
    const errors = [];
    if (isObject(data)) {
        if ("message" in data) {
            errors.push({ error: data.message.toString() });
        }
        if ("error" in data) {
            errors.push({ error: data.error.toString() });
        }
        if ("errors" in data) {
            const error = data.errors as Array<{ field: string; errors: Array<string> }>;
            Object.keys(error).forEach((err) => {
                errors.push({ key: err, error: error[err].pop() });
            });
        }
    }
    return errors;
};

export const plausibleEvent = (event: string, props: string | undefined = undefined) => {
    // @ts-ignore
    window.plausible =
        // @ts-ignore
        window.plausible ||
        function () {
            // @ts-ignore
            if (!window.plausible) window.plausible = { q: [] };
            // @ts-ignore
            (window.plausible.q = window.plausible.q || []).push(arguments);
        };

    // @ts-ignore
    const plausible = window.plausible;

    if (props !== undefined) {
        plausible(event, { props: { name: props } });
    } else {
        plausible(event);
    }
};

export const extractCost = (value: string | number) => {
    if (isNumber(value)) {
        return Intl.NumberFormat("de-AT", { style: "currency", currency: "EUR" }).format(value * 1.2);
    } else {
        try {
            return Intl.NumberFormat("de-AT", { style: "currency", currency: "EUR" }).format(
                parseFloat(value.replace(",", ".")) * 1.2,
            );
        } catch {
            return value;
        }
    }
};
